<template>
  <div class="form-content">
    <el-form ref="form" :model="form">
      <el-form-item :label="$t('Ingredient Name')" style="max-width: 80%">
        <el-input v-model="form.ingredientName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Ingredient Image')" style="max-width: 80%">
        <el-upload
        :action="uploadUrl"
        :file-list="tmpFile"
        :on-preview="onPreview"
        :on-success="onUploaded"
        :on-remove="onRemove"
        :limit="1"
        list-type="picture-card">
        <i class="el-icon-plus"></i>
      </el-upload>
      </el-form-item>
      <el-form-item :label="$t('Ingredient Categories')" style="max-width: 80%">
        <el-checkbox-group v-model="form.categories">
          <el-checkbox :key="categorie.id" v-for="categorie in categories" :label="categorie.name"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('Ingredient Stock')" style="max-width: 10%">
        <el-input type="number" v-model="form.stock"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Ingredient Cost ($)')" style="max-width: 10%">
        <el-input type="number" v-model="form.cost"></el-input>
      </el-form-item>
    </el-form>
    <div class="submit-wrapper">
      <el-button class="submit-button" type="primary" @click="submit">{{ $t('Submit') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Ingredient from '@/lib/ingredient';

export default {
  name: 'IngredientForm',
  mounted(){
    this.uploadUrl = `${this.apiUrl}/ingredient/file`;
    if(this.targetRestaurant){
      this.form.restaurant = this.targetRestaurant.id;
    }
    this.loadCategories();
  },
  methods:{
    submit(){
      this.$emit('action', this.form);
    },
    onPreview(file){
      window.open(file.url, "_blank");
    },
    onRemove(file){
      this.form.brandLogo = '';
    },
    async onUploaded(response, file, fileList){
      const fileUrl = response['image_url'];
      this.form.ingredientPhoto = fileUrl;
    },
    getCategoriesById(categorieIds){
      const categories = this.categories.filter(function(categorie){
        if(categorieIds.includes(categorie.id)){
          return true;
        }
      });
      const categorieNames = [];
      for(let i = 0; i < categories.length; i++){
        if(this.langcode === 'zh'){
          categorieNames.push(categories[i].categorie_name_zh);
        }else{
          categorieNames.push(categories[i].categorie_name_en);
        }
      }
      return categorieNames;
    },
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Ingredient.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    const form = {
      ingredientName: '',
      categories: [],
      ingredientPhoto: '',
      cost: 0,
      stock: 0,
    };
    return {
      tmpFile: [],
      uploadUrl: '',
      form,
      categories: [],
    }
  },
  watch: {
    'currentMeal': async function(){
      const form = {
        categories: [],
        categories: [],
        mealName: this.currentMeal.name,
        mealDescription: this.currentMeal.description,
        published: true,
        mealPhoto: this.currentMeal.image,
        regularPrice: this.currentMeal.regular_price,
        restaurant: this.currentMeal.related_restaurant,
      };

      if(this.currentMeal.status == 1){
        form.published = true;
      }else{
        form.published = false;
      }

      //Load Categories Name
      form.categories = this.getCategoriesById(this.currentMeal.category);
      if(this.currentMeal.image){
        const imageFileName = this.currentMeal.image.substring(this.currentMeal.image.lastIndexOf('/')+1);
        const imageFile = {
          name: imageFileName,
          url: this.currentMeal.image,
        };
        this.mealFiles.push(imageFile);
      }
      this.form = form;
    },
    'langcode': function(){
      this.form.categories = this.getCategoriesById(this.currentMeal.category);
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
    currentIngredient: state => state.menu.currentIngredient,
    targetRestaurant: state => state.brand.targetRestaurant,
  }),
}
</script>

<style lang="scss">
.form-content{
  padding-left: 20px;
  .el-form-item__label{
    float: none;
  }
  .update-button{
    .el-form-item__content{
      text-align: right;
    }
  }
  .submit-wrapper{
    text-align: right;
  }
}
</style>
